import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { get } from 'miniruche/services/api';
import PageHeader from 'miniruche/ui/layout/PageHeader.jsx';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import { getToken } from 'modules/api/authentication';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import SaleSummary from 'miniruche/components/sale/SaleSummary.jsx';
import MinirucheMapSection from 'miniruche/components/miniruche/MinirucheMapSection.jsx';
import { Page, Section, Stack, Text, Button, Heading, Image, URLShare } from 'miniruche/ui';
import CurrentDayOrders from './CurrentDayOrders.jsx';
import FeaturedSaleSummary from './FeaturedSaleSummary.jsx';
import { getLocaleShort, getCountryFromLocal } from 'modules/utils/locales.js';

export class Sales extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    state = {
        error: null,
        isMiniHost: false,
        miniruche: {},
        sales: [],
        status: INITIAL_LOADING,
        user: null,
    };

    componentDidMount() {
        const token = getToken('access_token');
        const miniRucheRequest = get(`miniruche/${this.props.slug}`);
        const userRequest = !!token ? get('miniruche/users/me') : Promise.resolve(null);
        Promise.all([userRequest, miniRucheRequest]).then(this.onDataLoaded, this.onError);
    }

    onDataLoaded = ([user, miniruche]) => {
        const isMiniHost = !!user && miniruche.facilitator === user.uuid;
        this.setState({
            miniruche,
            user,
            isMiniHost,
            status: isMiniHost ? INITIAL_LOADING : READY,
        });

        if (isMiniHost) {
            get(`miniruche/admin/${this.props.slug}/sales`).then(sales => {
                this.setState({
                    sales,
                    status: READY,
                });
            }, this.onError);
        }
    };

    onError = error => {
        switch (error.status) {
            case 404:
                return this.setState({
                    status: 'READY',
                    error: 'not_found',
                });
            case 403:
                return this.setState({
                    status: 'READY',
                    error: 'unauthorized',
                });
            default:
                return this.setState({
                    status: 'READY',
                    error: 'default',
                });
        }
    };

    onSaleClicked = saleId => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'sale_clicked',
            eventLabel: saleId,
        });
    };

    getEmailFromLocal = () => {
        const { i18n } = this.props;

        switch (getLocaleShort(i18n)) {
            case 'it':
                return 'assistenza@alvearechedicesi.it';
            case 'es':
            case 'ca':
                return 'asistencia@lcqds.es';
            case 'be':
            case 'nl':
                return 'helpdesk@boerenenburen.be';
            case 'de':
                return 'hilfe@marktschwaermer.de';
            default:
                return 'support@lrqdo.fr';
        }
    };

    getHelpCenterLinkFromLocal = () => {
        const { i18n } = this.props;

        switch (getCountryFromLocal(i18n)) {
            case 'DE':
                return 'https://hilfe.marktschwaermer.de/hc/de/articles/360009977217-Was-sind-Abholpunkte-und-wie-funktionieren-sie-';
            case 'NL':
                return 'https://boerenenburen.zendesk.com/hc/nl';
            case 'IT':
                return 'https://alvearechedicesi.zendesk.com/hc/it/categories/360002652320-Mini-Alveari-In-costruzione-';
            case 'ES':
            case 'CA':
                return 'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/360002312937-MINI-COLMENAS';
            default:
                return 'https://support.laruchequiditoui.fr/hc/fr/categories/9522761090716';
        }
    };

    render() {
        const { sales, status, miniruche, isMiniHost, user, error } = this.state;

        const { slug, trans } = this.props;

        if (status === INITIAL_LOADING) {
            return <Page />;
        }

        if (error === 'not_found') {
            return (
                <Page>
                    <Section>
                        <Heading rank={1} size="small" className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.notFound')}
                        </Heading>
                        <Text>{trans('miniruche.sales.check')}</Text>
                    </Section>
                </Page>
            );
        }

        if (error === 'unauthorized') {
            return (
                <Page>
                    <Section>
                        <Heading rank={1} size="small" className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.unauthorizedHeading')}
                        </Heading>
                        <Text className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.unauthorizedText')}
                        </Text>
                        <Button variant="primary" to={`login?redirectPath=${this.props.slug}`}>
                            {trans('login.login')}
                        </Button>
                    </Section>
                </Page>
            );
        }

        if (error === 'default') {
            return (
                <Page>
                    <Section>
                        <Heading rank={1} size="small" className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.defaultErrorHeading')}
                        </Heading>
                        <Text className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.defaultErrorMessage')}
                        </Text>
                    </Section>
                </Page>
            );
        }

        const openedSales = miniruche.sales.sort((s1, s2) => (s1.date < s2.date ? -1 : 1));

        const dayPart = dateTimeIsoStr => dateTimeIsoStr.substring(0, 10);

        const currentDaySalesWithOrder = sales
            .filter(sale => sale.orderCount > 0)
            .filter(sale => dayPart(sale.date) === dayPart(new Date().toISOString()));

        return (
            <Page>
                <PageHeader user={user} userLinks title={miniruche.name} alternate={false} />
                <CurrentDayOrders sales={currentDaySalesWithOrder} slug={miniruche.slug} />
                <Section>
                    {openedSales.length === 0 ? (
                        <div>
                            <Heading rank={1} size="small" className="u-spacing-stack-m">
                                {trans('miniruche.sales.memberEmpty.title')}
                            </Heading>
                            <Text>{trans('miniruche.sales.memberEmpty')}</Text>
                            <Image
                                style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}
                                src="/assets/images/illustrations/miniruche-nosale.gif"
                            />
                        </div>
                    ) : (
                        <div>
                            <FeaturedSaleSummary
                                sale={openedSales[0]}
                                slug={miniruche.slug}
                                className="u-spacing-stack-xl"
                                onClick={() => this.onSaleClicked(openedSales[0].id)}
                            />
                            {openedSales.length > 1 && (
                                <Fragment>
                                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                                        {trans('miniruche.sales.otherOngoingSales')}
                                    </Heading>
                                    {R.tail(openedSales).map(sale => (
                                        <SaleSummary
                                            key={sale.id}
                                            sale={sale}
                                            slug={miniruche.slug}
                                            className="u-spacing-stack-l"
                                            onClick={() => this.onSaleClicked(sale.id)}
                                        />
                                    ))}
                                </Fragment>
                            )}
                        </div>
                    )}
                </Section>
                <MinirucheMapSection miniruche={miniruche} withContactInformation={!isMiniHost} />
                {isMiniHost && (
                    <Section>
                        <Button
                            block
                            className="u-spacing-stack-m"
                            variant="outline"
                            to={`/${slug}/admin/sales`}
                        >
                            {trans('global.dashboard')}
                        </Button>
                    </Section>
                )}
                <Section>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        {trans('miniruche.sales.helpAndContact')}
                    </Heading>
                    <Stack className="u-spacing-stack-s">
                        <a href={this.getHelpCenterLinkFromLocal()} target="_blank">
                            {trans('global.helpCenter')}
                        </a>
                        <a href={`mailto:${this.getEmailFromLocal()}`} target="_blank">
                            {this.getEmailFromLocal()}
                        </a>
                    </Stack>
                </Section>
                <Section alternate>
                    <Heading rank={1} size="large" className="u-spacing-stack-xl">
                        {trans('miniruche.admin.sales.invite')}
                    </Heading>
                    <Text className="u-spacing-stack-l">{trans('miniruche.create.shareText')}</Text>
                    <URLShare
                        className="u-spacing-stack-xl"
                        url={`https://miniruche.com/${this.props.slug}`}
                        messageTitle={trans('miniruche.admin.sales.inviteButtonTitle')}
                        messageContent={trans('miniruche.admin.sales.inviteButtonContent')}
                    />
                </Section>
            </Page>
        );
    }
}

export default connectToI18n(({ match, i18n, trans }) => (
    <Sales i18n={i18n} trans={trans} slug={match.params.slug} />
));
